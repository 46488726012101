import React, { useEffect, useRef } from "react";
import { TweenMax, Power3, TimelineMax } from "gsap";
import * as styles from "./index.module.css";

// markup
const IndexPage = () => {
  let index = useRef(null);
  let eyeLeft = useRef(null);
  let eyeRight = useRef(null);
  let circle = useRef(null);
  let smiley = useRef(null);
  let logo = useRef(null);
  useEffect(() => {
    let tl = new TimelineMax({ repeat: -1, repeatDelay: 3 });
    TweenMax.to(index, 0, { visibility: "visible" });
    TweenMax.from(logo, 2, {
      opacity: 0,
      ease: Power3.easeOut,
      y: 10,
    });
    TweenMax.to(circle, 3, {
      fill: "red",
      ease: Power3.easeOut,
      repeat: -1,
      yoyoEase: true,
    });
    TweenMax.to(smiley, 3, {
      y: -20,
      ease: Power3.easeOut,
      repeat: -1,
      yoyoEase: true,
      delay: 0.4,
    });
    tl.staggerTo(
      [eyeLeft, eyeRight],
      0.2,
      {
        scaleY: 0,
        yoyoEase: true,
        repeat: 1,
        transformOrigin: "center",
      },
      0.1
    );
  }, []);
  return (
    <main className={styles.container} ref={(el) => (index = el)}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 1224.4 277.4"
        className={styles.logo}
        ref={(el) => (logo = el)}
      >
        <path
          className={styles.letters}
          d="M81.6 239.2c-25.2 0-43.3-7.9-58-7.9-6.9 0-9.2 1.6-11.8 7.9H8.5V36.1c0-11.1-2.3-17-8.5-22.6v-2C31.5 8.8 57.7 5.2 66.2 0l2.3 1c-.7 4.3-1 14.4-1 24.6v62.6h.7c5.2-11.5 22-19.3 40-19.3 36.4 0 61 32.1 61 79.6-.1 56.7-35.2 90.7-87.6 90.7zm-.3-147.8c-7.2 0-11.8 4.9-13.8 13.8v115.7c1 4.3 5.9 7.2 12.1 7.2 17.7 0 27.2-23.6 27.2-68.2 0-43.6-9.1-68.5-25.5-68.5z"
        />
        <path
          className={styles.letters}
          d="M260.5 219.6h-.7c-16.4 13.1-30.5 19.7-44.6 19.7-22.6 0-39.7-17-39.7-42.3 0-22 13.1-39 48.8-60.3l19.7-11.8c14.7-8.8 21.3-17.4 21.3-27.9 0-9.8-5.9-16.4-15.4-16.4-12.1 0-19.7 10.8-19.7 27.5 0 6.6 1.3 12.8 2.6 14.4l-1.6 1.6-47.2-19c9.8-22.3 36.7-36.4 69.8-36.4 41.3 0 64.6 22.3 64.6 61.6v71.1c0 11.8 2.3 18 8.5 24.3v2c-17.4 1.6-49.8 7.2-67.5 11.5l-1-1 2.1-18.6zm-10.1-5.9c3.9 0 6.9-1.3 9.2-3.9v-81.9l-3.6 2.6c-13.8 9.8-22.6 30.5-22.6 53.7-.1 19 5.8 29.5 17 29.5z"
        />
        <path
          className={styles.letters}
          d="M396.2 239.2c-17 0-26.5-5.9-38.7-5.9-5.6 0-10.5 1.3-14.4 3.3l-1-.7-10.5-58.7 1.6-.7c27.5 37.4 44.9 51.5 61.3 51.5 12.1 0 19-7.9 19-18.4 0-34.1-73.4-41.9-73.4-93.1 0-27.9 22-47.8 54.7-47.8 19 0 32.1 6.6 41 6.6 3.9 0 7.2-1.3 10.2-3.3l1 .7-13.1 38h-1.6C424.7 91.8 412 80 396.9 80c-8.5 0-13.8 3.9-13.8 10.8 0 24.6 73.4 32.4 73.4 89.8 0 33.7-25.9 58.6-60.3 58.6z"
        />
        <path
          className={styles.letters}
          d="M613.2 182.9c-8.2 37-31.8 56.4-68.8 56.4-47.2 0-77.3-31.5-77.3-81.3 0-52.1 32.8-89.1 80.6-89.1 39 0 65.9 24.9 65.9 73.1h-86.8c2.9 41 18 62.3 43.9 62.3 15.7 0 29.5-7.9 40.3-22.3l2.2.9zM526 130.8h35.7c0-33.1-6.9-50.8-19-50.8-11.1 0-16.7 15.4-16.7 45.9v4.9z"
        />
        <path
          className={styles.letters}
          d="M705.9 68.8c9.2 0 17 1.3 20.3 3.3v-36c0-11.1-2.3-17-8.5-22.6v-2c31.5-2.6 57.7-6.2 66.2-11.5l2.3 1c-.7 4.3-1 14.4-1 24.6v177.6c0 11.1 2.3 17 8.5 22.6v2c-31.5 2.6-57.7 6.2-66.2 11.5l-2.3-1 2-20h-.7c-5.9 12.5-22.3 21-41 21-36.4 0-61-32.1-61-79.6.1-56.8 35.2-90.9 81.4-90.9zm6.6 147.8c7.2 0 11.8-4.9 13.8-13.8V87.2c-1-4.3-5.9-7.2-12.1-7.2-17.7 0-27.2 23.6-27.2 68.2-.1 43.5 9.1 68.4 25.5 68.4z"
        />
        <path
          className={styles.letters}
          d="M882.2 7.2V4.9h85.9v2.3c-6.6 5.6-10.2 18.4-10.2 34.7v178.6h9.5c23.3 0 36.7-7.2 68.8-37.4l1.3.7-12.8 48.2c-.7 2-3.3 2.3-7.5 2.3h-135V232c6.6-5.6 10.2-18 10.2-34.4V41.9c0-16.3-3.6-29.1-10.2-34.7z"
        />
        <path
          className={styles.letters}
          d="M1204.4 197.3c7.9 22 11.1 27.5 20 34.7v2.3h-90.1V232c8.5-6.2 8.8-12.8 2-32.1l-.7-2.3H1075c-5.9 18.4-4.6 26.9 6.2 34.4v2.3h-42.9V232c8.8-7.2 12.1-12.8 20-34.7L1129 0h4.6l70.8 197.3zm-73.8-13.4l-26.5-74.1-24.6 74.1h51.1z"
        />
        <g ref={(el) => (smiley = el)}>
          <path d="M831.3 262.7c-7 0-13-2.5-18-7.4-5-5-7.4-10.9-7.4-18 0-7 2.5-13 7.4-18 5-5 11-7.4 18-7.4s13 2.5 18 7.4c5 5 7.4 10.9 7.4 18 0 7-2.5 13-7.4 18-5 4.9-11 7.4-18 7.4z" />
          <path
            ref={(el) => (circle = el)}
            className={styles.circle}
            d="M831.3 259.5c6.1 0 11.4-2.2 15.7-6.5 4.3-4.3 6.5-9.5 6.5-15.7 0-6.1-2.2-11.3-6.5-15.7-4.3-4.3-9.6-6.5-15.7-6.5s-11.4 2.2-15.7 6.5c-4.3 4.3-6.5 9.6-6.5 15.7s2.2 11.3 6.5 15.7c4.4 4.3 9.6 6.5 15.7 6.5z"
          />
          <path d="M818.4 250.4h-2c.5-4.5 2-8.1 4.7-10.7 2.7-2.6 6.1-3.9 10.2-3.9 4.2 0 7.6 1.3 10.2 3.9 2.7 2.6 4.2 6.1 4.7 10.7h-2c-1.6-7-5.9-10.5-12.9-10.5s-11.3 3.5-12.9 10.5z" />
          <path
            ref={(el) => (eyeLeft = el)}
            d="M823.2 233.3c-1.1 0-2.1-.4-2.8-1.2-.8-.8-1.2-1.7-1.2-2.8s.4-2.1 1.2-2.8c.8-.8 1.7-1.2 2.8-1.2 1.1 0 2.1.4 2.9 1.2.8.8 1.2 1.7 1.2 2.8s-.4 2.1-1.2 2.8c-.8.8-1.8 1.2-2.9 1.2z"
          />
          <path
            ref={(el) => (eyeRight = el)}
            d="M839.4 233.3c-1.1 0-2.1-.4-2.9-1.2-.8-.8-1.2-1.7-1.2-2.8s.4-2.1 1.2-2.8c.8-.8 1.7-1.2 2.9-1.2 1.1 0 2.1.4 2.8 1.2.8.8 1.2 1.7 1.2 2.8s-.4 2.1-1.2 2.8c-.7.8-1.7 1.2-2.8 1.2z"
          />
          <path d="M845.8 205.1l10.8 4.5c6.6 2.7 11.9 7.1 15.8 13 4 5.9 6 12.5 6 19.6 0 9.8-3.4 18.1-10.3 25-6.9 6.9-15.2 10.3-25 10.3-4.6 0-9.1-.9-13.4-2.7l-10.8-4.5c-6.6-2.7-11.9-7-15.8-12.9-4-5.9-6-12.5-6-19.6 0-9.7 3.4-18 10.3-24.9 6.9-6.9 15.2-10.3 24.9-10.3 4.3-.2 8.8.7 13.5 2.5zm-44 33c0 8.2 3 15.3 9 21.2 6 5.9 13.2 8.9 21.5 8.9 8.4 0 15.6-3 21.6-9 6-6 9-13.2 9-21.6 0-8.4-3-15.6-9-21.6-6-6-13.2-9-21.6-9-8.5 0-15.8 3-21.7 9-5.9 6.1-8.8 13.4-8.8 22.1z" />
        </g>
      </svg>
    </main>
  );
};

export default IndexPage;
